import React from 'react'
import PropTypes from 'prop-types';
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from "../components/AppLayout";
import PageInfo from '../components/PageInfo'
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { AccentSection } from './index'
import AnchorLink from '../components/shared/AnchorLink'
import Typography from '@material-ui/core/Typography'
import SignUpAndDemoButtons from '../components/SignUpAndDemoButtons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RouterLink from '../components/shared/RouterLink'
import Brand from '../components/shared/Brand'
import Spacing from '../reused-libraries/Spacing'
import cn from 'classnames';
import { TimesheetAppBarsBg } from '../components/shared/display/AngledBarsBg'
import HeaderNavigation from '../components/page/HeaderNavigation'
import { TrustSignals } from '../components/common/TrustSignals'
import { ProductVideo } from '../components/common/ProductVideo'
import { buildHowToExtra, LandingHowTo } from '../components/common/LandingHowTo'
import { ForceLightTheme } from '../reused-libraries/materialTheme'

const useTimesheetAppStyles = makeStyles(theme => ({
	mainTagline: {
		backgroundColor: "#dee2e6", // gray-300
		padding: "3rem 0",
		marginBottom: theme.spacing(6),
	},
	afterBarsBg: {
		position: "relative",
		backgroundColor: theme.palette.background.default,
	},
	section: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
	},
}), {name: "TimesheetApp"});

export default function TimesheetApp(props) {
	let {data} = props;
	let {productName} = useSiteMetadata();

	const classes = useTimesheetAppStyles();

	return (
		<AppLayout excludeHeaderNav>
			<PageInfo
				title={"Online Timesheet App - Try " + productName + " Timesheet Tracker For Free"}
				description={productName+ "'s online timesheet app is easy to use and designed for freelancers and business teams. Use our free online timesheet app or upgrade and get extra features."}
			/>

			<TimesheetAppBarsBg>
				<HeaderNavigation logoVariant="whiteout"/>

				<Spacing pt={12}>
					<BenefitsHeadline/>
					<CallToAction/>
					<ForceLightTheme>
						<TrustSignals>
							<TimesheetTrustSignal/>
						</TrustSignals>
					</ForceLightTheme>
					<ProductVideo
						title="Timesheet App Video"
						youTubeVideoId="TjmCW5zRuo8"
						description="A brief overview of how to use Timesheets in LogSpace."
						uploadDate="2020-01-24"
					/>
				</Spacing>
			</TimesheetAppBarsBg>

			<div className={classes.afterBarsBg}>
				<MainTagline className={classes.mainTagline}/>
				<HowTo className={classes.section} data={data}/>
				<Features className={classes.section}/>
				{/* Testimonial could also be placed here? */}
				<FAQ className={classes.section}/>
			</div>

		</AppLayout>
	);
}
const useBenefitsHeadlineStyles = makeStyles(theme => ({
	mainTagline: {
		fontSize: theme.typography.pxToRem(35),
		[theme.breakpoints.up('md')]: {
			fontSize: theme.typography.pxToRem(38.5),
		},
	},
}), {name: "BenefitsHeadline"});

function BenefitsHeadline(props) {
	const classes = useBenefitsHeadlineStyles();
	return (
		<header className="container-fluid px-sm-5 text-center">
			<Typography variant="h1" gutterBottom className={classes.mainTagline}>
				Easy To Use Free Online Timesheet App
			</Typography>
			{/*<Typography component="h2" variant="h6" gutterBottom>*/}
			{/*	Paperless Cloud Based Timesheets You Can Access Anywhere*/}
			{/*</Typography>*/}
			<Typography variant="body2">
				<Brand/> timesheets are an excellent way for employees and contractors to track time while working on projects.<br/>
				Look at your hours on an easy-to-read timesheet, download reports, and send invoices.
			</Typography>
		</header>
	);
}
BenefitsHeadline.propTypes = {

};

const useCallToActionStyles = makeStyles(theme => ({
	ctaContainer: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
	},
}), {name: "CallToAction"});

function CallToAction(props) {
	const classes = useCallToActionStyles();
	return (
		<section className={classes.ctaContainer}>
			<div className="container-fluid">
				<SignUpAndDemoButtons
					centered
					jumbo
					includePlanNote
				/>
			</div>
		</section>
	);
}
CallToAction.propTypes = {

};

const useTimesheetTrustSignalStyles = makeStyles(theme => ({
	quoteMark: {
		// marginRight: theme.spacing(2),
	},
	quote: {
		fontWeight: 300,
		fontStyle: "italic",
		marginBottom: "1em",
	},
	quoteAttribution: {
		textAlign: "right",
	},
}), {name: "TimesheetTrustSignal"});

function TimesheetTrustSignal(props) {
	const classes = useTimesheetTrustSignalStyles();
	return (
		<React.Fragment>
			<Spacing m={3}>
				{/*<FontAwesomeIcon icon="quote-left" size="2x" className={classes.quoteMark}/>*/}
				<FontAwesomeIcon icon="globe-americas" size="2x" className={classes.quoteMark}/>
			</Spacing>

			<Typography className={classes.quote}>
				LogSpace timesheets are stored online.  Access your data from anywhere on the globe!
				{/*Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum orci diam, viverra convallis augue porta et. Vestibulum.*/}
			</Typography>

			{/*<Typography className={classes.quoteAttribution}>*/}
			{/*	&mdash; Bob "The Testimonial" Guy*/}
			{/*</Typography>*/}
		</React.Fragment>
	);
}

function MainTagline(props) {
	let {className} = props;
	let {productName} = useSiteMetadata();
	return (
		<section className={className}>
			<div className="container-fluid">
				<h2>
					Paperless Timesheet Software for Tracking Weekly Hours and More
				</h2>
				<p>
					We know that a free timesheet app has to cover the basics such as logging
					time. <Brand/> Time Tracker has the features you expect. But it also sets
					itself apart with features like expenses and invoicing. And we have a
					dedication to making {productName} easy to use. {productName} is
					available on the browser, iPhone, and Android. Our system stores your
					data online, so it is always available on all your devices.
				</p>
				<p>
					{productName} was built for <RouterLink to="/freelance-time-tracking/">freelancers</RouterLink>, contractors, employers, and hourly workers.
					It can manage your <RouterLink to="/features/">time</RouterLink> and <RouterLink to="/features/expenses/">expenses</RouterLink>.
					If you have clients, {productName} can <RouterLink to="/features/invoicing/">automatically create
					invoices</RouterLink> based on your schedule, so you'll spend hardly any
					time getting paid for the time you work.
				</p>
				<p>
					On a team plan, {productName} allows you to set permissions so that your
					teammates can view your timesheets, expenses, and invoices.
				</p>
				<p>
					We are also {productName} users, and we share the desire for our time
					management tools to be out of the way. Our goal is to make your work a
					priority, not time and expense management. So we do our best to keep your
					time tracking out of the way while still accomplishing a satisfying time
					management experience.
				</p>
			</div>
		</section>
	);
}
MainTagline.propTypes = {
	className: PropTypes.string,
};

function buildHowToNumberedItems(productName, data) {
	return [
		{
			// TODO: move link to siteData
			title: (<React.Fragment>Start using your {productName} timesheet app with a free account.</React.Fragment>),
			content: null,
			img: null,
		},
		{
			title: "Open the timesheet screen",
			content: (
				<ul>
					<li>To find the timesheet screen, choose the "Timesheet" option in the navigation menu.</li>
				</ul>
			),
			img: {
				imgData: data.timesheetMenu.childImageSharp.gatsbyImageData,
				alt: "Selecting timesheet on the menu",
			},
		},
		{
			title: "Go to the desired week",
			content: (
				<ul>
					<li>When you start, the current week will be selected.</li>
					<li>Use the week navigation buttons to go to a different week if desired.</li>
				</ul>
			),
			img: {
				imgData: data.timesheetWeekSelection.childImageSharp.gatsbyImageData,
				alt: "Picking the week on the timesheet",
			},
		},
		{
			title: "If necessary, add projects to the timesheet",
			content: (
				<ul>
					<li>If the project you want is not in the current timesheet, you can add it with the "Add Project" button.</li>
					<li>Any projects that already have hours logged will be shown in the timesheet automatically.</li>
					<li>You can also create more projects by visiting "Projects".</li>
				</ul>
			),
			img: {
				imgData: data.timesheetAddProjectMenu.childImageSharp.gatsbyImageData,
				alt: "Adding a project to the timesheet",
			},
		},
		{
			title: "Enter Time Worked",
			content: (
				<ul>
					<li>Look for the desired day. Then click in the box and type a number to enter the hours worked for that day.</li>
					<li>You can also use "My Time" to "Clock In" if you have a running task.</li>
				</ul>
			),
			img: {
				imgData: data.timesheetEnterTime.childImageSharp.gatsbyImageData,
				alt: "Entering hours on the timesheet",
			},
		},
		{
			title: "Save the entries",
			content: (
				<ul>
					<li>When you are done, press the "Save" button to save your new time entries.</li>
					<li>You can also press "Discard Changes" to cancel all the entries you made.</li>
				</ul>
			),
			img: {
				imgData: data.timesheetSaveButton.childImageSharp.gatsbyImageData,
				alt: "Saving the timesheet",
			},
		},
	];
}

function HowTo(props) {
	let {className, data} = props;
	let {productName} = useSiteMetadata();
	return (
		<LandingHowTo
			className={className}
			header="How to Enter Hours on Timesheets"
			numberedItems={buildHowToNumberedItems(productName, data)}
			nonNumberedHeader="Once you have saved your timesheet, you can also:"
			nonNumberedItems={buildHowToExtra(productName, data)}
		/>
	);
}
HowTo.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object.isRequired,
};

const features = [
	{
		title: "Timesheets",
		description: "Enter time worked. Clock in for active jobs.",
	},
	{
		title: "Expenses",
		description: "Save your expenses.",
	},
	{
		title: "Invoicing",
		description: "Create and send invoices for your time and expenses. Send invoices to clients.",
	},
	{
		title: "Team Members",
		description: "Invite others to your team.",
	},
	{
		title: "Reports & Exports",
		description: "Export time and expenses in CSV or Excel.",
	},
	{
		title: "Time Log Imports",
		description: "Import data from other systems.",
	},
	{
		title: "iPhone and Android Mobile App",
		description: "View and enter your time on the go.",
	},
	{
		title: "Projects",
		description: "Create multiple projects for various tasks.",
	},
	{
		title: "Rounding Modes",
		description: "Round your time, such as in 6 or 15 minute increments.",
	},
];

const useFeaturesStyles = makeStyles(theme => ({
	root: {
		"& h3": {
			display: "list-item",
			listStyleType: "disc",
			marginLeft: theme.spacing(2),
			marginBottom: "0.5em",
		},
	},
	b: {
		fontWeight: "500",
	},
	accentSectionHeading: {
		fontSize: theme.typography.pxToRem(45),
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.pxToRem(35),
		},
	},
}), {name: "Features"});

function Features(props) {
	const {className} = props;
	const classes = useFeaturesStyles();
	return (
		<AccentSection className={cn(classes.root, className)}>
			<Typography variant="h2" className={classes.accentSectionHeading} gutterBottom>Timesheet Features</Typography>

			{features.map(({title, description}, idx) => (
				<Typography key={idx} component="h3" variant="body1" className={classes.b}>{title}.<Typography component="span" variant="body2">{' '}{description}</Typography></Typography>
			))}

			{/*TODO: Mention hourly rates?*/}
		</AccentSection>
	);
}
Features.propTypes = {
	className: PropTypes.string,
}

const frequentlyAskedQuestions = [
	{
		question: "What is a timesheet and how does it work?",
		answer: (
			<React.Fragment>
				As described by Wikipedia, a <AnchorLink href="https://en.wikipedia.org/wiki/Timesheet">timesheet</AnchorLink> (or time sheet) is a method for recording the amount of a worker's time spent on each job.
				You can use a timesheet to track time worked without needing to record specific start and stop times. On the timesheet app, you can enter the number of hours you worked for the day, such as "8.5".
			</React.Fragment>
		),
	},
	{
		question: "What is a digital timesheet?",
		answer: (
			<React.Fragment>
				A digital timesheet is managed with the aid of a computer system such as this <Brand/> timesheet
				app. This includes benefits such as the ability to access the
				timesheet app from a mobile device and also the ability to collaborate with team
				members.
			</React.Fragment>
		),
	},
	{
		question: "Why should a business use timesheets?",
		answer: (
			<React.Fragment>
				Timesheets are a well established method of tracking and reporting time for employees as well as independent contractors.
				They provide a concise report of time worked and can be categorized by project as well.  Great for project management!
			</React.Fragment>
		),
	},
	{
		question: "How can I keep track of employee hours?",
		answer: (
			<React.Fragment>
				Using an online time tracking system like <Brand/> that offers digital timesheets is a great way for employees to record
				hours worked.  Employers can use employee timesheet data to view reports of time worked.
			</React.Fragment>
		),
	},
	{
		question: "Why is a timesheet important?",
		answer: (
			<React.Fragment>
				A timesheet is a simple tool that can be used to track time worked on projects.  Tracking time worked on projects can be important for many
				reasons such as if the time will need to be billed to a client, or an employee is to be paid for the time, or if a project leader
				simply wants to know where a team's time is being used.
			</React.Fragment>
		),
	},
	{
		question: 'Is it a "timesheet" or "time sheet"?',
		answer: 'Either is fine, but we prefer "timesheet"',
	},
	{
		question: "Is LogSpace Time Tracker really free?",
		answer: (
			<React.Fragment>
				Yes! Get Started for free! We
				also have a plan available for a monthly fee that includes invoicing and supports extra team members.
			</React.Fragment>
		),
	},
	// {
	// 	question: "Can I provide specific work times?",
	// 	answer: "Yes, you may enter one or more entries, optionally including times and descriptions.",
	// },
	// {
	// 	question: "What is LogSpace Time Tracker?",
	// 	answer: "LogSpace Time Tracker is a free time and expense tracker. It is a free time tracking tool for contractors and employers to record productivity. With an optionally upgraded plan, it lets you send invoices and manage team members.",
	// },
	// {
	// 	question: "How do you use LogSpace Time Tracker?",
	// 	answer: 'Press the "Clock In" button, and you\'re started! Press the "Clock Out" button when your time ends. You may also just enter an amount of time on your timesheet.',
	// },
	{
		question: "Is there a mobile app for LogSpace Time Tracker?",
		answer: "Yes. We have an iPhone and Android mobile app, so you can easily log time from your mobile device.",
	},
	{
		question: "Is my LogSpace data synchronized on all my devices?",
		answer: "Yes. Your data is stored securely online and is synchronized when viewed on all your devices. Your data is also backed up, so it is safe from a computer failure.",
	},
	// {
	// 	question: "Does LogSpace monitor my computer or take screenshots?",
	// 	answer: "No. LogSpace only records the time and expenses you enter. It does not monitor the activity on your computer or mobile device.",
	// },
	{
		question: "Can I send an invoice for the time I worked?",
		answer: (
			<React.Fragment>
				Yes, you can create invoices that include your work hours. You may include time and
				expenses from your teammates as well.
			</React.Fragment>
		),
	},
	{
		question: "Does LogSpace support automatic invoicing for my timesheet entries and expenses?",
		answer: (
			<React.Fragment>
				Yes, we support automatic invoicing for monthly, weekly, bi-weekly, or semi-monthly
				schedules. After you set up the invoicing period for a client, <Brand/> can easily calculate work hours and
				automatically create the invoice for each period. These invoices can include time
				entered on timesheets as well as expenses.
			</React.Fragment>
		),
	},
	// {
	// 	question: "Which LogSpace features require a paid plan?",
	// 	answer: (<React.Fragment>Please see our <RouterLink to="/pricing/">pricing page</RouterLink> to get a quick feature comparison between the plans.</React.Fragment>),
	// },
	// {
	// 	question: "Does LogSpace Time Tracker generate logarithmically spaced vectors?",
	// 	answer: (<React.Fragment>Sorry, no. We're just a space to log your time and expenses. There are other sites if you're looking for a <AnchorLink href="https://docs.scipy.org/doc/numpy/reference/generated/numpy.logspace.html">math equation to generate logarithmically spaced vectors</AnchorLink>.</React.Fragment>),
	// },
];

function FAQ(props) {
	const {className} = props;
	return (
		<section className={className}>
			<div className="container-fluid">
				<Typography variant="h2" gutterBottom>FAQ</Typography>

				{frequentlyAskedQuestions.map(({question, answer}, idx) => (
					<React.Fragment key={idx}>
						<Typography component="h2" variant="h5">{question}</Typography>
						<Typography paragraph variant="body2">{answer}</Typography>
					</React.Fragment>
				))}
			</div>
		</section>
	);
}
Features.propTypes = {
	className: PropTypes.string,
}

export const query = graphql`{
  timesheetAddProjectMenu: file(relativePath: {eq: "images/howto_timesheet/timesheet_add_project_menu.png"}) {
    childImageSharp { gatsbyImageData(width: 251, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  timesheetEnterTime: file(relativePath: {eq: "images/howto_timesheet/timesheet_enter_time.png"}) {
    childImageSharp { gatsbyImageData(width: 251, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  timesheetMenu: file(relativePath: {eq: "images/howto_timesheet/timesheet_menu.png"}) {
    childImageSharp { gatsbyImageData(width: 251, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  timesheetSaveButton: file(relativePath: {eq: "images/howto_timesheet/timesheet_save_button.png"}) {
    childImageSharp { gatsbyImageData(width: 251, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  timesheetWeekSelection: file(relativePath: {eq: "images/howto_timesheet/timesheet_week_selection.png"}) {
    childImageSharp { gatsbyImageData(width: 251, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
